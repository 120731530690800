<template>
    <textarea ref="input" :disabled="props.noEdit" v-model="value"
    :class="{'o365-textarea-noresize': noResize}" v-bind="$attrs"
    @keydown="onKeyDown"
    @input="onInput" :rows="rows"></textarea>
</template>

<script setup>
    import { dataGridEditorCellControlKey } from 'o365.modules.vue.injectionKeys.js';
    import { ref, watch, computed, onMounted, onUpdated, inject, onActivated } from 'vue';

    const props = defineProps({
        autoGrow: Boolean,
        noResize: Boolean,
        noEdit: Boolean,
        rowHeight: {
            type: [Number, String],
            default: 28
        },
        rows: {
            type: [Number, String],
            default: 2
        },
        modelValue: null
    });

    const emit = defineEmits(['update:modelValue']);

    const value = computed({
        get() { return props.modelValue; },
        set(newValue) { emit('update:modelValue', newValue); }
    });

    const editorApi = inject(dataGridEditorCellControlKey, null);
    if (editorApi) {
        editorApi.activatePopupMode();
    }

    const input = ref(null);

    function calculateInputHeight() {
        if (!input.value) { return; }
        input.value.style.height = '0'
        const height = input.value.scrollHeight
        const minHeight = parseInt(props.rows, 10) * parseFloat(props.rowHeight) + 6;
        input.value.style.height = Math.max(minHeight, height+2) + 'px'
    }

    function onInput(e) {
        if (props.autoGrow) { calculateInputHeight(); }
    }

    function onKeyDown(e) {
        if (editorApi && e.key === 'Enter' && e.shiftKey) {
            e.stopPropagation();
        }
    }

    function activateEditor() {
        const el = input.value;
        if (el) { el.focus(); }
    }

    function getControl(){
        return input.value
    }

    onMounted(() => {
        if (props.autoGrow) { calculateInputHeight(); }
    });

    onUpdated(() => {
        if (props.autoGrow) { calculateInputHeight(); }
    });

    onActivated(() => {
        if (props.autoGrow) { 
            window.setTimeout(() => {
                calculateInputHeight();
            }, 50);
        }
    });

    defineExpose({ activateEditor, getControl, calculateInputHeight });
</script>

<style scoped>
    .o365-textarea-noresize {
        resize: none;
    }
</style>